@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.ant-layout .ant-layout-header {
  background: none;
}
